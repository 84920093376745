<template>
  <div id="why-rubii-page">
    <div
      class="banner cvr-bg medium-title"
      style="background-image: url('/img/dummy/about/wrb/0.jpg');"
    >
      <div class="wrapper">
        <h1>{{ title }}</h1>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of banner -->

    <div class="inner-sidebar">
      <div class="wrapper rowflex">
        <sidebar-about></sidebar-about>
        <article class="main">
          <div class="inner-wrb">
            <div class="hentry" v-html="description"></div>
            <!-- end of hentry -->

            <div class="list">
              <template v-for="(item, index) in content">
                <div class="item" :key="index">
                  <div class="htitle">
                    <figure v-if="index < 4">
                      <img
                        :src="`/img/dummy/about/wrb/${index + 1}.png`"
                        width="62"
                      />
                    </figure>
                    <figure v-else>
                      <img src="/img/dummy/about/wrb/5.png" width="62" />
                    </figure>
                    <h2>{{ item.header }}</h2>
                  </div>
                  <!-- end of htitle -->
                  <div class="caption">
                    <div class="format-text">
                      <p v-html="item.body"></p>
                    </div>
                    <!-- end of format text -->
                  </div>
                  <!-- end of caption -->
                </div>
              </template>
              <!-- end of item -->
            </div>
            <!-- end of list -->
          </div>
          <!-- end of innner wrp -->
        </article>
        <!-- end of inner waw -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of inner aside -->
  </div>
</template>

<script>
import SidebarAbout from "./SidebarAbout";
export default {
  name: "WhyRubii",
  metaInfo: {
    title: "Why Rubii"
  },
  components: {
    SidebarAbout
  },
  data() {
    return {
      menuId: 2,
      title: null,
      banner: null,
      description: null,
      content: []
    };
  },
  mounted() {
    this.getContent();
  },
  methods: {
    getContent() {
      this.$axios
        .get(`/about-us/${this.menuId}`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          if (response.data.success) {
            this.title = response.data.data.title;
            this.banner = response.data.data.banner;
            this.description = response.data.data.descriptions;
            this.content = response.data.data.contents;
          }
        });
    }
  }
};
</script>
